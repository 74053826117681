<template>
    <el-row class="container e-flex e-flex-center">
        <el-row class="login-box" type="flex">
            <el-col :span="14" class="login-bg"></el-col>
            <el-col :span="10">
                <div class="login-inputs">
                    <div class="e-flex">
                        <p class="main-text e-font-20 e-weight-font">用户登录</p>
                        <div class="e-flex-right">
                            <div class="btn1" @click="goPage('school-login')">统一学习</div>
                        </div>
                    </div>
                    <div class="input-row e-m-t-48">
                        <el-input class="e-font-14" v-model="name" placeholder="请输入用户名"></el-input>
                    </div>
                    <div class="input-row e-m-t-55">
                        <el-input type="password" class="e-font-14" v-model="psw" placeholder="请输入密码"></el-input>
                    </div>
                    <el-row class=" e-m-t-50">
                        <!-- <el-col :span="14" class="input-row"> -->
                            <!-- <el-input class="e-font-14" v-model="code" placeholder="验证码"></el-input> -->
                        <!-- </el-col> -->
                        <!-- <el-col :span="8" :offset="2"> -->
                            <!-- <div class="code-img e-flex e-flex-center" @click="captche">
                                <img class="code-img" :src="codeUrl" alt="无">
                            </div> -->
                        <!-- </el-col> -->
                    </el-row>
                    <el-button class="e-m-t-55 login-btn" type="success" @click="submit">登录</el-button>
                </div>
                <div class="foot-info">
                    <p class="sub-text e-font-12 e-p-l-15">
                        忘记密码？请联系技术服务公司
                        <span class="orange-font">0871-65825996</span>
                    </p>
                </div>
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            name: '',
            psw: '',
            code: '',
            codeUrl: ''
        };
    },
    created() {
        this.captche();
        // 回车 触发提交事件
        let that = this
        document.onkeydown = function (e) {
            let event = window.event || e
            let key = event.keyCode || event.which || event.charCode
            if(key == 13){
                that.submit()
            }
        }
    },
    activated(){
        this.captche();
    },
    methods: {
        // 验证码
        captche() {
            return
            let that = this;
            that.$get({
                url: this.$wp + '/captcha'
            }).then(function (res) {
                that.codeUrl = res.data;
            })
        },
        goPage(name) {
            this.$router.push({
                name: name
            });
        },
        getCaption:function (obj){
            let ia = obj.lastIndexOf("|");
            return obj.substring(ia+1,obj.length);
        },
        submit() {
            let self = this;
            //登录
            self.$post({
                url: self.$wp + '/login',
                data: {
                    account_number: self.name,
                    login_password: self.psw,
                    // captcha: self.code
                }
            }).then(function (res) {
                // console.log('login',res)
                if (res.code == 200) {
                    self.name = ''
                    self.psw = ''
                    self.code = ''
                    //存储token
                    localStorage.setItem('token', self.getCaption(res.data.token))
                    sessionStorage.setItem('yhm',res.data.name)
                    self.$router.push({
                        path: '/index/home'
                    });
                } else {
                    alert(res.errorMsg);
                    self.captche()
                    // alert('登录失败,稍后重试!')
                }
            })


        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: url('../../assets/index-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .login-box {
        width: 50%;
        height: 600px;
        background: #fff;
        border-radius: 16px;
        position: relative;

        .login-bg {
            height: 100%;
            border-radius: 16px 0 0 16px;
            background-image: url('../../assets/login-box-bg.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .login-inputs {
            padding: 94px 57px 70px 57px;
        }

        .btn1 {
            color: #77c87f;
            border: 1px solid #77c87f;
            font-size: 14px;
            padding: 4px 12px;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
                background: #77c87f;
                color: #fff;
            }
        }

        .input-row {
            border-bottom: 1px solid #d8d8d8;
        }

        .code-img {
            height: 39px;
            background: #e1fae0;
            color: #5ebf59;
        }

        .login-btn {
            width: 100%;
        }
    }

    .orange-font {
        cursor: pointer;
    }
}

/deep/ .el-input__inner {
    border: 0 !important;
    padding: 0;
}
</style>
