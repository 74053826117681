import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    type:'1'
  },
  mutations: {
    setType(state,type){
      state.type = type
    }
  },
  actions: {
  },
  modules: {
  }
})
