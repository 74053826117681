<template>
    <el-row>
        <el-row type="flex">
            <el-col :span="18" class="user-card">
                <div class="user-bg">
                    <p class="e-font-24 main-text e-weight-font">欢迎您，{{ userInfo.name }}</p>
                    <p class="tag e-font-14 e-m-t-18">学生</p>
                </div>
                <div class="enters e-flex e-p-20 e-relative">
                    <div class="e-text-center pointer"  @click="goPage('test',3,1)" >
                        <img src="../../assets/home/test-list.png" class="enter-img">
                        <p class="e-font-14 main-text">考试列表</p>
                    </div>
                    <div class="border-right"></div>
                    <div class="e-text-center pointer"  @click="goPage('test',3,2)" >
                        <img src="../../assets/home/history-list.png" class="enter-img"/>
                        <p class="e-font-14 main-text">历史考卷</p>
                    </div>
                    <img src="../../assets/home/enter1.png" alt="" class="enter-img1">
                </div>
            </el-col>
            <el-col :span="6" class="base-info e-m-l-14">
                <div class="e-p-l-24 e-p-r-24 e-p-t-12 e-font-14 e-weight-font">
                    <img src="../../assets/home/base.png" alt="">
                    <div class="theme-text e-flex e-m-t-24">
                        <p class="label">姓 名：</p>
                        <p>{{ userInfo.name }}</p>
                    </div>
                    <div class="theme-text e-flex e-m-t-10">
                        <p class="label">就读学校：</p>
                        <p>{{ userInfo.school_name }}</p>
                    </div>
                    <div class="theme-text e-flex e-m-t-10">
                        <p class="label">入学年份：</p>
                        <p>{{ userInfo.admission_time }}</p>
                    </div>
                    <div class="theme-text e-flex e-m-t-10">
                        <p class="label">当前年级：</p>
                        <p>{{ userInfo.grade_name }}</p>
                    </div>
                    <div class="theme-text e-flex e-m-t-10">
                        <p class="label">班 级：</p>
                        <p>{{ userInfo.class_name }}</p>
                    </div>
                    <div class="theme-text e-flex e-m-t-10">
                        <p class="label">账 号：</p>
                        <p>{{ userInfo.account_number }}</p>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="tables e-m-t-14">
            <div class="e-flex e-p-20">
                <img src="../../assets/home/class-list.png" alt="">
                <div class="e-flex-right">
                    <div class="more-btn" @click="goPage('online-study',2,'')">
                        查看更多
                    </div>
                </div>
            </div>
            <el-table
                max-height="400px"
                :data="tableMu"
                :header-cell-style="headerRow"
                style="width: 100%">
                <el-table-column
                    label="课程序号"
                    align="center"
                    prop="number">
                </el-table-column>
                <el-table-column
                    label="科目"
                    align="center"
                    prop="subject_name">
                </el-table-column>
                <el-table-column
                    label="课程名称"
                    align="center"
                    prop="name">
                </el-table-column>
                <el-table-column
                    label="学习时间"
                    align="center"
                    prop="study_time">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="btn-img" @click="goDetail(scope.row)"></div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="e-p-t-20 e-p-b-20">
                <pagination :pageIndex="pageIndex" :pageSize="pageSize" :pageTotal="pageTotal"
                            @pageChange="pageChange"></pagination>
            </div>
        </div>
    </el-row>
</template>

<script>
import Pagination from "@/components/pagination"

export default {
    name: "home",
    components: {
        Pagination
    },
    data() {
        return {
            userInfo: {},
            pageIndex: 1,
            pageSize: 5,
            pageTotal: 0,
            name: '', //课程名称
            subjects_id: 0, //科目id
            tableMu: [],
            tableData: [],
            headerRow: {
                background: '#F2FBF7',
                padding: '20px 0',
                color: '#05170F',
                fontSize: '14px'
            }
        }
    },
    mounted(){
        this.initInfo();
        this.initData();
    },
    activated() {
        this.initInfo()
        this.initData()
    },
    methods: {
        initInfo: function () {
            let self = this;

            self.$post({
                url: self.$wp + '/student/info',
                // headers: {
                //     Authorization: 'Bearer ' + localStorage.getItem('token')
                // }
            }).then(function (res) {
				// sessionStorage.setItem('yhm', res.data.name)
                self.userInfo = res.data;
            })
        },
        initData: function () {
            let self = this;
            self.$post({
                url: self.$wp + '/student-course',
                data: {
                    page: self.pageIndex,
                    page_size: self.pageSize, //每页条数
                }
            }).then(function (res) {
                self.tableMu = res.data;
                self.pageIndex = res.meta.current_page;
                self.pageTotal = res.meta.total;
            })
        },
        goDetail(val) {
            this.$router.push({
                name: 'class-detail', params: {val: val}
            })
        },
        startLearn(index) {
            console.log(index)
        },
        pageChange(val) {
            this.pageIndex = val
            this.initData();
        },
        goPage(name, val,tpNow) {
            this.$emit('currentChange',val)
            switch (val) {
                case 1:
                case 2:
                    sessionStorage.setItem('currentIndex', val)
                    break
                default:
                    sessionStorage.setItem('typeNow', tpNow)
            }
             this.$router.push({
                name: name
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.user-card {
    border-radius: 10px;

    .user-bg {
        padding: 32px 24px;
        background-image: url("../../assets/home/user-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .tag {
            display: inline-block;
            padding: 4px 12px;
            background: #fff;
            color: #27C982;
            border-radius: 4px;
        }
    }

    .enters {
        background: #fff;
        border-radius: 0 0 10px 10px;
        justify-content: space-evenly;
        position: relative;

        .border-right {
            border-right: 1px solid #DCE6E3;
            height: 60px;
        }

        .enter-img {
            width: 72px;
            height: 72px;
        }

        .enter-img1 {
            position: absolute;
            left: 20px;
            top: 14px;
            width: 104px;
            height: 33px;
        }
    }
}

.base-info {
    background: #fff;
    border-radius: 10px;
}

.btn-img {
    margin: 0 auto;
    width: 88px;
    height: 36px;
    background-image: url("../../assets/start-learn1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        background-image: url("../../assets/start-learn2.png") !important;
    }
}

.tables {
    background: #fff;
    border-radius: 10px;
}

.more-btn {
    background: #E2FFF2;
    color: #27C982;
    font-size: 14px;
    padding: 6px 14px;
    cursor: pointer;
}
</style>
