<template>
    <el-row class="main-body container">
        <div class="header">
            <div class="e-flex e-flex-center e-p-t-12 e-font-20 main-text e-weight-font">
                <!--                <p>2021年 三年级考试卷</p>-->
                <p>{{ exm.title }}</p>
                <p class="e-m-l-60">{{ exm.subject_name }}</p>
            </div>
            <div class="e-flex e-flex-center e-p-t-4 e-font-14 main-text">
                <p>满分{{ exm.total_score }}分</p>
                <p class="e-m-l-20">单选题{{ exm.fraction.single_number * exm.fraction.single_score }}分</p>
                <p class="e-m-l-20">多选题{{ exm.fraction.mulit_number * exm.fraction.mulit_score }}分</p>
                <p class="e-m-l-20">判断题{{ exm.fraction.judge_number * exm.fraction.judge_score }}分</p>
            </div>
        </div>
        <el-row class="body">
            <el-col :span="17" class="left">
                <div class="test-body e-m-r-14">
                    <div class="title e-flex">
                        <p class="e-font-20 e-weight-font main-text">{{question.typeStr}}</p>
                        <p class="sub-text e-font-14 ">{{question.countInfo}}</p>
                        <p class="e-flex-right e-font-14">考试时间：<span class="time">{{ exm.duration }}</span>分钟</p>
                    </div>
                    <div class="content">
                        <div class="e-flex">
                            <div class="e-font-14 main-text e-flex" v-html="(questionIndex+1)+' '+question.name"></div>
                            <!--<div class="e-font-14 main-text" v-html="(questionIndex+1)+' '+htmlDecode(question.name)"></div>-->
                            <el-button type="success" class="e-m-l-12" v-if="question.example" @click="popup4 = !popup4">查看示例</el-button>
                        </div>
                        <div class="selection e-flex e-font-14 e-m-t-10" v-for="(item,idx) in answers" :key="item.id" :class="item.selected && item.class" @click="pickOne(item,idx)">
                            <div class="label e-flex pointer">
                                <img :src="item.src"  alt="" class="e-m-r-12">
                                <img src="../../assets/test/selected.png" v-if="item.selected" alt="" class="e-m-r-12">
                                <img src="../../assets/test/unselect.png" v-else alt="" class="e-m-r-12">
                                {{question.type!=3 ? ABDC[idx] : ''}}
                            </div>
                            <div class="e-m-l-20">
                                <div v-html="htmlDecode(item.content)"></div>
                            </div>
                            <div class="e-flex-right" v-if="item.selected && item.class=='current'">
                                <img src="../../assets/test/current.png" alt="">
                            </div>
                            <div class="e-flex-right" v-else-if="item.selected && item.class=='wrong'">
                                <img src="../../assets/test/wrong.png" alt="">
                            </div>
                        </div>
                        <div class="e-font-14 main-text" v-if="mode!='exam'">
                            <div>正确答案：<span v-for="(item,idx) in answers" :key="idx" v-text="item.right==1 ? (question.type!=3 ? ABDC[idx] : item.content) : ''"></span></div>
                            <div>答案解析：<div v-html="question.resolve"></div></div>
                        </div>
                    </div>
                    <div class="content-foot e-flex">
                        <div class="e-flex e-flex-right">
                            <div class="btn" :class="{'disabled':questionIndex<=0}" @click="goPrev">上一题</div>
                            <div class="btn e-m-l-30" v-if="mode=='exam' && questionIndex+1>=exm.question_lists.length" @click="checkSubmit">交卷</div>
                            <div class="btn e-m-l-30" v-else :class="{'disabled':questionIndex+1>=exm.question_lists.length}"  @click="goNext">下一题</div>

                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="7" class="right">
                <div class="left-time e-text-center" >
                    <p class="e-font-14 main-text e-weight-font">{{mode=='exam' ? '剩余时间':'考试成绩'}}</p>
                    <div class="e-m-t-8 time-box">
                        <count-down ref="countDown"  @countDownEnd="ending" v-if="mode=='exam'"></count-down>
                        <div class="red-font" v-else v-text=" undefined != exm.score_sum && undefined != exm.use_time ? '总分：'+exm.score_sum+' 完成时间：'+exm.use_time : ''"></div>
                    </div>
                </div>
                <div class="test-list e-m-t-14">
                    <p class="main-text e-font-14 e-p-t-14 e-weight-font e-text-center">答题卡</p>
                    <div class="e-flex e-flex-wrap">
                        <div class="test-num" @click="goIndex(item,idx)"
                             :class="{'e-m-r-0':item.selected==false && idx!=questionIndex && (item.result==undefined || item.result==0) ,
                                'type1':item.selected==true && idx!=questionIndex && item.result==undefined ,'type3':idx==questionIndex,'type4':idx!=questionIndex && item.result==-1,'type5':idx!=questionIndex && item.result==1}"
                             v-for="(item,idx) in exm.question_lists" :key="item.id">{{ idx+1 }}
                        </div>
                    </div>
                    <div class="e-flex types e-font-14">
                        <div class="e-text-center">
                            <div class="type1 type"></div>
                            <p class="e-m-t-6">已答</p>
                        </div>
                        <div class="e-text-center e-m-l-12">
                            <div class="type2 type"></div>
                            <p class="e-m-t-6">未答</p>
                        </div>
                        <div class="e-text-center e-m-l-12">
                            <div class="type3 type"></div>
                            <p class="e-m-t-6">当前</p>
                        </div>
                        <div class="e-text-center e-m-l-12">
                            <div class="type4 type"></div>
                            <p class="e-m-t-6">错误</p>
                        </div>
                        <div class="e-text-center e-m-l-12">
                            <div class="type5 type"></div>
                            <p class="e-m-t-6">正确</p>
                        </div>
                    </div>
                    <div class="btn e-text-center e-m-t-14" @click="checkSubmit">
                        {{mode=='exam' ? '立即交卷' : '返回'}}
                    </div>
                </div>
            </el-col>
        </el-row>

        <!-- 交卷 -->
        <el-dialog
            :visible.sync="popup"
            :show-close="false"
            width="30%">
            <div class="popup-box e-rela">
                <div class="e-text-center">
                    <img src="../../assets/test/tips.png" alt="">
                </div>
                <div class="main-text e-text-center e-weight-font e-p-l-80 e-p-r-80 e-m-t-30 e-font-18">
                    试题已答完，确定交卷吗？
                </div>
                <div class="e-flex e-flex-center e-m-t-30">
                    <img src="../../assets/test/cancel-btn.png" alt="" @click="popup = false" class="pointer">
                    <img src="../../assets/test/sure-btn.png" alt="" @click="submits" class="pointer">
                </div>
            </div>
        </el-dialog>

        <!-- 未做完交卷/自动交卷 -->
        <el-dialog
            :visible.sync="popup2"
            :show-close="false"
            width="30%">
            <div class="popup-box e-rela">
                <div class="e-text-center">
                    <img src="../../assets/test/tips.png" alt="">
                </div>
                <div class="main-text e-text-center e-weight-font e-p-l-80 e-p-r-80 e-m-t-30 e-font-18">
                    {{ totalTime === 0 ? '倒计时结束，已自动交卷' : '还有未答完的题目，不能提前交卷' }}
                </div>
                <div class="e-flex e-flex-center e-m-t-30">
                    <img src="../../assets/test/sure-btn.png" alt="" @click="confirmPop" class="pointer">
                </div>
            </div>
        </el-dialog>

        <!-- 交卷分数 -->
        <el-dialog
            :visible.sync="popup3"
            :show-close="false"
            width="30%">
            <div class="popup-box e-rela">
                <!-- <img src="../../assets/detail/close.png" alt="" class="close-img pointer" @click="popup3 = false"> -->
                <div class="e-text-center">
                    <img src="../../assets/test/test-result.png" alt="">
                </div>
                <div class="main-text e-text-center e-weight-font e-p-l-80 e-p-r-80 e-m-t-30 e-font-18">
                    <p class="e-font-16 main-text"><span class="huge-font">{{results.score_sum}}</span>分</p>
                    <p class="red-font e-m-t-12">错题数：{{results.error_count}}</p>
                </div>
                <div class="e-flex e-flex-center e-m-t-30">
                    <img src="../../assets/test/go-detail-btn.png" alt="" @click="toSeeLog" class="pointer">
                </div>
            </div>
        </el-dialog>

        <!-- 事例 -->
        <el-dialog
            :visible.sync="popup4"
            :show-close="false"
            width="65%">
            <div class="popup-box2" v-if="question.example">
                <div class="e-flex">
                    <div class="e-flex-right">
                        <img src="../../assets/detail/close.png" alt="" @click="popup4 = false" class="pointer">
                    </div>
                </div>
                <div class="imgs" v-if="question.example.type == 'image'">
                    <img :src="question.example.url?question.example.url:''" class="img-now"/>
                </div>
                <div class="videos" v-if="question.example.type == 'video'">
                    <video ref="videoPlay"  controls="controls" autoplay="autoplay" :src="question.example.url" class="videos" loop>
                    </video>
                </div>
            </div>
        </el-dialog>
    </el-row>
</template>

<script>
import CountDown from "@/components/count-down"

export default {
    name: "examination",
    components: {
        CountDown
    },
    data() {
        return {
            // contentTitle: `<p>题目标题</p>`,
            // contentA: `<p>这个是第一题</p>`,
            // contentB: `<p>这个是第二题</p>`,
            // contentC: `<p>这个是第三题</p>`,
            // contentD: `<p>这个是第四题</p>`,
            exam_id:'',
            log_id:'',
            selectedList: [],
            totalTime: 0,
            popup: false,
            popup2: false,
            popup3: false,
            popup4:false,
            id: 0,
            exm: {fraction:{single_number:0,single_score:0,
                mulit_number:0,mulit_score:0,
                judge_number:0,judge_score:0,

                },question_lists:[{
                        answers:[],
                    }
                ]
            }, //试卷信息
            question:{name:''}, //当前题目
            questionIndex: 0,//当前题目序号
            ABDC:{0:'A',1:'B',2:'C',3:'D',4:'E',5:'F',6:'G',7:'H',8:'I',9:'J',10:'K'},
            answers:{},
            myAnswers:{},
            results:[], //考试结果
            onsubmit : false,
            mode: 'exam',
            timer:'',
            fileType:'',
            submited : false,
        }
    },
    mounted() {
        let params = this.$route.params
        if(params.val){
            sessionStorage.setItem('examParams',JSON.stringify(params))
        } else {
            let str = sessionStorage.getItem('examParams')
            params = JSON.parse(str)
        }
        this.checkMode(params)
    },
    activated(){
        this.popup=false
        this.popup2=false
        this.popup3=false
        this.popup4=false
        let params = this.$route.params
        if(params.val){
            sessionStorage.setItem('examParams',JSON.stringify(params))
        } else {
            let str = sessionStorage.getItem('examParams')
            params = JSON.parse(str)
        }
        this.checkMode(params)
    },
    disactivated(){
        clearInterval(this.timer)
    },
    methods: {
        checkMode(params){
            console.log('checkmode',params)
            if(params.logview==1){
                this.log_id = params.val.id;
                this.mode= 'log'
                this.toSeeLog();
            }else{
                this.exam_id = params.val.id;
                this.mode= 'exam'
                this.initExam(params.val.id);
            }
        },
        htmlDecode(str) {
            let e = document.createElement('div');
            e.innerHTML = str;
            return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
        },
        //初始化试卷信息
        initExam(id) {
            let that = this;

            that.$post({
                url: that.$wp + '/test-paper/'+id,
                // headers: {
                //     Authorization: 'Bearer ' + localStorage.getItem('token')
                // },
                data: {
                    test_pare: that.id
                }
            }).then(function (res) {
                console.log('paper',res)
                that.submited = false
                that.exm = res.data
                that.totalTime = res.data.duration * 60
                that.$refs.countDown.reinit(that.totalTime)
                that.questionIndex = 0
                that.setupQuestion(res.data.question_lists[0])
                that.start_time = that.getDatetime()
                let that1 = that
                clearInterval(that1.timer)
                that.timer = setInterval(() => {
                    that1.totalTime--
                    that1.totalTime<=0 && clearInterval(that1.timer)
                }, 1000);
            })
        },
        setupQuestion(q){
            console.log('q',q)
            if(this.onsubmit) return
            let score = 0;
            let count = 0;
            this.question = q
            // this.question.id = q.id
            // this.question.name = q.name
            // this.question.type = q.type
            switch(q.type){
                case 1:
                    this.question.typeStr = '一、单选题'
                    score = this.exm.fraction.single_score
                    count = this.exm.fraction.single_number
                    break;
                case 2:
                    this.question.typeStr = '二、多选题'
                    score = this.exm.fraction.mulit_score
                    count = this.exm.fraction.mulit_number
                    break;
                case 3:
                    this.question.typeStr = '三、判断题'
                    score = this.exm.fraction.judge_score
                    count = this.exm.fraction.judge_number
            }
            this.question.countInfo = '（每题'+score+'分,答错不得分，共'+count+'题）'
            this.answers = q.answers
            this.question.example = q.example
            if(q.example && q.example.type == 'video'){
                this.$nextTick(() => {
                    this.$refs.videoPlay.load()
                })
            }
            
            //{'current':true}  {'wrong':true}

        },
        pickOne(ans,idx){
            console.log(ans,idx,this.question)
            if(this.onsubmit || this.mode!='exam') return

            if(this.question.type!=2 && ans.selected){ //非多选题
                return
            }
            let that = this;
            let res = [];
            let answers = this.answers
            answers.forEach((element,index) => {
                if(that.question.type!=2){ //非多选题
                    if(index == idx){
                        element.selected = true
                        // element.src = '../../assets/test/selected.png'
                        res.push(element.id);
                    }else{
                        element.selected = false;
                        // element.src = '';
                    }
                }else{
                    if(index == idx){
                        element.selected = !element.selected
                        // element.src = element.selected ? '../../assets/test/selected.png' : ''
                    }
                    element.selected && res.push(element.id);
                }
            });

            this.exm.question_lists[this.questionIndex].answers = answers;
            this.answers = answers;

            this.myAnswers[this.question.id] = res
            this.exm.question_lists[this.questionIndex].selected = res.length>0

            this.$forceUpdate()
            console.log('myAnswers',this.myAnswers)
            console.log('ans',this.answers)
            console.log('exm.ques',this.exm.question_lists)
            // this.exm.question_lists[this.questionIndex].answers[idx].selected = true
                //todo
        },
        goPrev() {
            if(this.onsubmit || this.questionIndex<1) return
            this.questionIndex--
            this.setupQuestion(this.exm.question_lists[this.questionIndex])
        },
        goNext() {
            if(this.onsubmit || this.questionIndex>=this.exm.question_lists.length-1) return
            this.questionIndex++
            this.setupQuestion(this.exm.question_lists[this.questionIndex])
        },
        goIndex(item,idx){
            console.log('goindex',idx)
            if(this.onsubmit || idx<0 || idx>this.exm.question_lists.length-1) return
            this.questionIndex = idx
            this.setupQuestion(this.exm.question_lists[idx])
        },
        checkSubmit(){ //提交按钮
            let that = this
            if(this.mode!='exam'){
                this.$router.back()
                return
            }
            if(this.submited){ //判断是否已交卷
                that.popup3 = true
                return
            }
            // console.log(this.totalTime)
            // let use_time = (this.exm.duration * 60 - this.totalTime)
            // console.log(use_time)
            // let hours = (Math.floor(parseFloat(use_time) / 3600.0))
            // let minutes = (Math.floor((parseFloat(use_time) - hours*3600.0)/60.0))
            // let seconds = use_time - hours*3600 - minutes*60
            // console.log(use_time,hours,minutes,seconds)
            // hours = hours>9 ? hours : '0'+hours
            // minutes = minutes>9 ? minutes : '0'+minutes
            // seconds = seconds>9 ? seconds : '0'+seconds
            // use_time = hours+':'+minutes+':'+seconds
            // console.log(use_time)

            if(this.onsubmit) return
            let alldown = true
            for(let i=0;i<this.exm.question_lists.length;i++){
                if(!this.exm.question_lists[i].selected){
                    alldown = false
                    break
                }
            }
            if(alldown){
                this.popup=true
            }else{
                this.popup2=true
            }
        },
        confirmPop(){
            if(this.onsubmit) return
            this.popup2=false
            // if(this.totalTime>0){
            //     this.popup2=false
            // }else{
            //     this.submits()
            // }
        },
        //倒计时结束触发
        ending() {
            if(this.popup3 || this.onsubmit  || this.mode!='exam') return
            this.popup = false
            this.popup2=true
            this.submits()
        },
        // 提交
        submits() {
            // console.log(this.totalTime)
            clearInterval(this.timer)
            // console.log(this.totalTime)
            let use_time = (this.exm.duration * 60 - this.totalTime)
            // console.log(use_time)
            if(this.onsubmit || this.mode!='exam') return
            this.onsubmit = true
            let that = this
            let hours = (Math.floor(use_time / 3600.0))
            let minutes = (Math.floor((use_time - hours*3600.0)/60.0))
            let seconds = use_time - hours*3600 - minutes*60
            // console.log(use_time,hours,minutes,seconds)
            hours = hours>9 ? hours : '0'+hours
            minutes = minutes>9 ? minutes : '0'+minutes
            seconds = seconds>9 ? seconds : '0'+seconds
            use_time = hours+':'+minutes+':'+seconds
            // console.log(use_time)
            // this.onsubmit = false
            // return

            // let answersList = {}
            // for(let i=0;i<this.exm.question_lists.length;i++){
            //     let question = this.exm.question_lists[i]

            //     if(undefined==this.myAnswers[question.id]){
            //         answersList.
            //     }else{
            //         answersList.push(this.myAnswers[question.id])
            //     }
            // }
            // console.log(answersList)

            that.$post({
                url: that.$wp + '/test-paper/answer',
                data: {
                    id: that.exam_id,
                    answers: JSON.stringify(this.myAnswers),
                    start_time : that.start_time,
                    use_time : use_time
                },
            }).then(function (res) {
                console.log('paper',res)
                that.onsubmit = false
                if(res.code!=200){
                    // this.$message(res.errorMsg)
                    alert(res.errorMsg)
                    return
                }
                that.results = res.data
                that.log_id = res.data.log_id
                that.onsubmit = false
                that.popup2 = false
                that.popup = false
                that.popup3 = true
                that.submited = true
            }).catch(function(err){
                console.log(err)
                that.onsubmit = false
                alert(res.errorMsg)
            })
        },
        toSeeLog(){
            this.mode = "log"
            let that = this
            this.$post({
                url: that.$wp + '/test-paper-log/'+this.log_id,
            }).then(function(res){
                console.log('reuslt_log',res)
                let tmpData = res.data
                // for(let i=0;i<tmpData.question_lists.length;i++){
                //     let tmpQuest = tmpData.question_lists[i]
                //     tmpQuest.submit_answer
                // }
                that.exm = res.data
                that.totalTime = res.data.duration * 60
                // that.$refs.countDown.reinit(that.totalTime)
                that.questionIndex = 0
                that.setupQuestion(res.data.question_lists[0])
                that.popup3=false
            }).catch(function(err){
                console.log(err)
                // setTimeout(()=>{
                //     that.$router.back()
                // },2000)
                alert(err.errorMsg)
            })
        },
        getDatetime() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            var day = now.getDate();
            var hh = now.getHours();
            var mm = now.getMinutes();
            var ss = now.getSeconds();
            var clock = year + "-";
            if (month < 10)
                clock += "0";
            clock += month + "-";
            if (day < 10)
                clock += "0";
            clock += day + " ";
            if (hh < 10)
                clock += "0";
            clock += hh + ":";
            if (mm < 10) clock += '0';
            clock += mm + ":";
            if (ss < 10) clock += '0';
            clock += ss;
            return clock;
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/total-bg.png");
}

.header {
    background-image: url("../../assets/test/head-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 1000px;
    height: 76px;
    margin: 70px auto 0 auto;
}

.body {
    width: 1000px;
    margin: 30px auto;
}

.test-body {
    background: #fff;
    border-radius: 10px;

    .title {
        padding: 14px 24px;
        background: #F2FBF7;
        border-radius: 10px 10px 0 0;
    }

    .content {
        background: #fff;
        padding: 24px 36px;
        max-height: 60vh;
        overflow-y: scroll;

        .selection {
            padding: 14px 30px;

        }

        .current {
            border: 1px solid #27C982;
        }

        .wrong {
            border: 1px solid #F54857;
        }
    }

    .content-foot {
        background: #F2FBF7;
        padding: 12px 26px;
        border-radius: 0 0 10px 10px;

        .disabled {
            background: #C5F1DD !important;
        }
    }
}

.btn {
    border-radius: 4px;
    background: #27C982;
    color: #fff;
    padding: 8px 58px;
    cursor: pointer;
}

.left-time {
    background: #fff;
    border-radius: 10px;
    padding: 14px 30px;

    .time-box {
        text-align: center;
        padding: 9px 0;
        background: #FFF9F5;
        color: #FF7B18;
        font-size: 20px;
        font-weight: bold;
        border-radius: 30px;
    }
}

.test-list {
    background: #fff;
    border-radius: 10px;
    padding: 14px 0 10px 12px;

    .btn {
        margin-right: 12px;
    }

    .test-num {
        border: 1px solid #D7D9D8;
        border-radius: 4px;
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        font-size: 14px;
        margin: 20px 12px 0 0;
        cursor: pointer;
    }

    .types {
        margin-top: 80px;

        .type {
            width: 26px;
            height: 26px;
            border-radius: 4px;
        }
    }

    .type1 {
        border: 1px solid #FAC517;
        background: #FAC517;
        color: #fff;
    }

    .type2 {
        border: 1px solid #D7D9D8;
    }

    .type3 {
        border: 1px solid #3A9CFF;
        background: #3A9CFF;
        color: #fff;
    }

    .type4 {
        border: 1px solid #F54857;
        background: #F54857;
        color: #fff;
    }

    .type5 {
        border: 1px solid #27C982;
        background: #27C982;
        color: #fff;
    }
}

.sub-text {
    color: #626664;
}

.time {
    color: #FF7B18;
}

.huge-font {
    font-size: 80px;
    font-weight: bold;
    color: #00D899;
}

.red-font {
    font-size: 14px;
    color: #FF7B18;
}


.close-img {
    position: absolute;
    right: 20px;
    top: 20px;
}

.popup-box2 {
    .img-now {
        width: 100%;
        height: 600px;
    }
    .videos {
        width: 100%;
        height: 600px;
    }
}

/deep/ .el-dialog__header {
    display: none !important;;
}

/deep/ .el-dialog {
    border-radius: 10px !important;
}

/deep/ .el-dialog__body {
    padding: 36px 30px !important;
}
</style>
