<template>
  <el-row class="header">
    <el-col :span="14" :offset="5">
      <!-- 统一学习 -->
      <div class="e-flex aa" v-if="hasToken ">
        <div class="e-flex">
            <img src="../assets/logo-text1.png" alt="" class="logo-img" @click="goPage('login')">
            <p class="theme-text e-font-14 e-m-l-26 e-weight-font">在线教育学习平台</p>
            <p v-if="schoolName !== ''" style="font-size: 15px;margin-left: 20px">{{schoolName}}</p>
        </div>

          <div style="display: flex">
              <div style="margin-left: 10px">
                  <img src="../assets/out.png" alt="" class="outImg" @click="out">
<!--                  <el-button @click="out">退出登录</el-button>-->
              </div>
          </div>
      </div>

      <!-- 首页 -->
      <div class="e-flex" v-else-if="froms === 'index'">
        <img src="../assets/logo-text1.png" alt="" class="logo-img e-m-r-30" @click="goPage('home',1)">
        <div class="menu-item e-flex e-m-l-80" @click="goPage('home',1)">
          <img src="../assets/total-head/index2.png" alt="" v-if="current === 1">
          <img src="../assets/total-head/index1.png" alt="" v-else>
          <p class="e-font-14 main-text e-m-l-4" :class="{'theme-text':current === 1}">首页</p>
        </div>
        <div class="menu-item e-flex e-m-l-60" @click="goPage('online-study',2)">
          <img src="../assets/total-head/online2.png" alt="" v-if="current === 2">
          <img src="../assets/total-head/online1.png" alt="" v-else>
          <p class="e-font-14 main-text e-m-l-4" :class="{'theme-text':current === 2}">在线教育</p>
        </div>
        <el-dropdown @command="selectMenu">
          <div class="menu-item e-flex e-m-l-60" >
            <img src="../assets/total-head/test2.png" alt="" v-if="current === 3">
            <img src="../assets/total-head/test1.png" alt="" v-else>
            <p class="e-font-14 main-text e-m-l-4" :class="{'theme-text':current === 3}">在线考试<i
                class="el-icon-arrow-down el-icon--right"></i></p>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">考试列表</el-dropdown-item>
            <el-dropdown-item command="2">历史考卷</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="e-flex-right e-flex e-font-14">
          <!--<img src="" alt="" class="avatar">-->
          <!-- <p class="main-text e-m-l-8">用户名</p> -->
          <p class="main-text e-m-l-8">{{ yhm }}</p>
          <p class="e-m-l-26 theme-text login-out" @click="loginOut">退出</p>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {loginOut,getSchoolLoginInfo} from "@/utils/common.js"
export default {
  props: ['froms'],
  name: "total-head",
  data() {
    return {
      current: 1,
	    yhm:'',
      hasToken:false,
        schoolName: ""
    }
  },
  activated(){
    this.onActive()
    let tokn = localStorage.getItem('token')
    this.hasToken = typeof(tokn) !== "undefined";
    if(this.froms === 'index' && 'class-detail'!=this.$router.currentRoute.name && tokn == undefined){
      this.$router.push({
        name: 'login'
      })
      return
    }

    let school = getSchoolLoginInfo();
    if(school != null && typeof(school.name) != "undefined"){
        this.schoolName = school.name;
    }
  },
  mounted() {
	  this.onActive();
  },
  methods: {
    out(){
        loginOut(this);
    },
    onActive(){
      this.yhm = sessionStorage.getItem('yhm')
      let index = sessionStorage.getItem('currentIndex')
      if (index) {
        this.current = index * 1
      } else {
        let name = this.$router.currentRoute.name
        switch (name) {
          case 'home':
            this.current = 1
            break
          case 'online-study':
            this.current = 2
            break
          case 'test':
            this.current = 3
            break
        }
      }
    },
    goPage(name, val) {
      if (val !== this.current) {
        this.current = val
        sessionStorage.setItem('currentIndex', val)
        if (val === '3') {
          sessionStorage.setItem('typeNow', '1')
        }
        setTimeout(() => {
          let name2 = this.$router.currentRoute.name
          if (name === name2) {
            this.$router.go(0)
          } else {
            this.$router.push({
              name: name
            })
          }
        }, 100)
      }
    },
    loginOut() {
      // sessionStorage.removeItem('yhm')
      localStorage.removeItem('token')
      sessionStorage.clear()
      // this.$router.go(0)
      this.$router.push({
        name: 'login'
      })
    },
    selectMenu(val) {
      if (3 !== this.current) {
        this.current = 3
        sessionStorage.setItem('currentIndex', 3)
      }
      sessionStorage.setItem('typeNow', val)
      let name = this.$router.currentRoute.name
        if (name === 'test') {
          this.$router.go(0)
        } else {
          this.$router.push({
            name: 'test'
          })
        }
      // setTimeout(() => {
      // }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 12px 0;
  background: #fff;

  .logo-img {
    width: 98px;
    height: 32px;
    cursor: pointer;
  }
}

.outImg{
    width: 20px;
    height: 20px;
    margin-top: 5px;
    cursor: pointer;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.menu-item {
  cursor: pointer;
}

.login-out {
  cursor: pointer;
}

.aa{
    justify-content: space-between
}

/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
  background: #F2FFF9 !important;
  color: #27C982 !important;
}
</style>
