<template>
  <div class="e-flex e-flex-center">

    <!-- 左箭头 -->
    <img src="../assets/prev-btn.png" alt="" class="btn-img e-m-r-12" @click="arrowleft(pageIndex)">

    <div class="page-item" v-if="pageIndex - 1 > 1" @click="jump(1)">
      1
    </div>
    <!-- 左侧省略号 -->
    <div class="page-item" :class="{'item-active': pageIndex-1 == pageIndex}" v-if="pageIndex - 1 > 2" @click="leftmore">
      ...
    </div>

    <!-- 激活页的前一页 -->
    <div class="page-item" :class="{'item-active': pageIndex-1 == pageIndex}" v-if="pageIndex - 1 > 0"
         @click="jump(pageIndex-1)">
      {{ pageIndex - 1 }}
    </div>

    <!-- 激活页 -->
    <div class="page-item" :class="{'item-active': pageIndex == pageIndex}" v-if="pageIndex <= num" @click="jump(pageIndex)">
      {{ pageIndex }}
    </div>

    <!-- 激活页的后一页 -->
    <div class="page-item" :class="{'item-active': pageIndex+1 == pageIndex}" v-if="pageIndex + 1 <= num "
         @click="jump(pageIndex+1)">
      {{ pageIndex + 1 }}
    </div>

    <!-- 右侧省略号 -->
    <div class="page-item" v-if="num - pageIndex - 2 >= 1" @click="rightmore">
      ...
    </div>

    <!-- 最后一页 -->
    <div class="page-item" v-if="num - pageIndex - 2 >= 0" @click="jump(num)">
      {{ num }}
    </div>

    <!-- 右箭头 -->
    <img src="../assets/next-btn.png" alt="" class="btn-img e-m-l-12" @click="arrowright(pageIndex)">
  </div>
</template>

<script>
export default {
  props: ['pageIndex', 'pageSize', 'pageTotal'],
  name: "pagination",
  data() {
    return {
      laveLeftPageShow: false, //左侧剩余显示
      laveRightPageShow: false, //右侧剩余显示
      showArrowRight: true,
      showArrowLeft: true,
      showMore: true
    }
  },
  methods: {
    jump(page) {
      if (!this.repeatClick && (this.pageIndex == page)) {
        return
      }
      this.autoAhowArrow(page)
    },
    // 隐藏剩余页数
    hideLave() {
      this.laveLeftPageShow = false
      this.laveRightPageShow = false
    },

    // 下一页
    arrowright(page) {
      if (page < this.num) {
        let nowPage = page + 1
        this.autoAhowArrow(nowPage)
      }
    },

    // 上一页
    arrowleft(page) {
      if (page > 1) {
        let nowPage = page - 1
        this.autoAhowArrow(nowPage)
      }
    },

    // 显示右侧更多
    rightmore() {
      if (this.showMore) {
        this.laveLeftPageShow = false
        this.laveRightPageShow = !this.laveRightPageShow
      }
    },

    // 显示左侧更多
    leftmore() {
      if (this.showMore) {
        this.laveRightPageShow = false
        this.laveLeftPageShow = !this.laveLeftPageShow
      }
    },

    // 自动显示左右箭头
    autoAhowArrow(nowPage) {
      if (nowPage <= 1) {
        this.showArrowLeft = false
        this.showArrowRight = true
      } else if (nowPage >= this.num) {
        this.showArrowLeft = true
        this.showArrowRight = false
      } else {
        this.showArrowLeft = true
        this.showArrowRight = true
      }
      this.$emit('pageChange', nowPage, this.pageSize)
      this.hideLave()
    }
  },
  computed: {
    // 总页数
    num() {
      return Math.ceil(this.pageTotal / this.pageSize)
    }
  },
}
</script>

<style lang="scss" scoped>
  .page-item{
    font-size: 20px;
    color: #B9BBBA;
    padding: 0 12px;
    cursor: pointer;
  }

  .item-active{
    color: #242525!important;
  }

  .btn-img{
    width: 40px;
    height: 40px;
  }
</style>