<template>
  <span>
    {{hour? hourString+':'+minuteString+':'+secondString : '00:' + minuteString+':'+secondString}}</span>
  
</template>

<script>
export default {
  name:'count-down',
  data () {
    return {
      hour: '',
      minute: '',
      second: '',
      promiseTimer: '',
      remainTime:''
    }
  },
  // props: {
  //   remainTime: {    // 倒计时间总秒数
  //     default: ''
  //   }
  // },
  mounted () {
    this.init()
  },
  methods: {
    reinit(t){
      this.remainTime = t
      console.log('reinit',this.remainTime)
      clearInterval(this.promiseTimer)
      this.init()
    },
    init(){
      if (this.remainTime > 0) {
            this.hour = Math.floor((this.remainTime / 3600) % 24)
            this.minute = Math.floor((this.remainTime / 60) % 60)
            this.second = Math.floor(this.remainTime % 60)
            this.countDown()
      }
    },
    countDown () {
      let self = this
      clearInterval(this.promiseTimer)
      this.promiseTimer = setInterval(function () {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            self.$emit('countDownEnd', true)
            clearInterval(self.promiseTimer)
          } else {
            self.second -= 1
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }
        }
      }, 1000)
    },
    formatNum (num) {
      return num < 10 ? '0' + num : '' + num
    }
  },
  computed: {
    hourString () {
      return this.formatNum(this.hour)
    },
    minuteString () {
      return this.formatNum(this.minute)
    },
    secondString () {
      return this.formatNum(this.second)
    }
  }
}
</script>