function getSchoolLoginInfo (){
    try{
        let loginInfo = window.localStorage.getItem("schoolLoginInfo");
        if(loginInfo === null || loginInfo.length === 0){
            return false;
        } else {
            loginInfo = JSON.parse(loginInfo);
            if(typeof(loginInfo.id) !== "undefined" && typeof(loginInfo.login_account) !== "undefined" && typeof(loginInfo.login_password) !== "undefined"){
                let time = (new Date()).valueOf();
                if(time > loginInfo.overDate){
                    return false;
                } else {
                    return loginInfo;
                }

            }  else {
                return false;
            }
        }
    }catch (e) {
        return false;
    }
}

function setSchoolLoginInfo(loginInfo){
    if(typeof(loginInfo) === "object"){
        loginInfo.overDate = (new Date()).valueOf() + 3600*24*6*30*1000;
        // loginInfo.overDate = (new Date()).valueOf() + 60*1000;
        window.localStorage.setItem("schoolLoginInfo", JSON.stringify(loginInfo));
    }
}

function loginOut(that) {
    window.localStorage.clear();
    that.$router.push({
        name: "login"
    });
}

export {
    getSchoolLoginInfo,
    setSchoolLoginInfo,
    loginOut
}
